import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import EventIcon from '@material-ui/icons/Event';

import styled from 'styled-components'



const BackgroundSection = ({ title }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "slider2-min.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1050
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }

  

      }
    `
  )

  const image = getImage(placeholderImage)
  
  // Use like this:
  const bgImage = convertToBgImage(image)

      return (
        <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        style={{ backgroundAttachment:'fixed' }}
      >
  <div style={{ backgroundColor:'rgba(0, 0, 0, 0.79)' }}>
   <Container>
       <Box pt={8} pb={6}>
<Grid container>
    <Grid item xs={12} align='center'>
    <Typography  gutterBottom variant="h3" component="h1" style={{ color:'#fff' }}>
     {title}
        </Typography>
        
    </Grid>
</Grid>
</Box>
   </Container>
   </div>
        </BackgroundImage>
      )
    }

const StyledBackgroundSection = styled(BackgroundSection)`
width: 100%;
background-position: bottom center;
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
`

export default StyledBackgroundSection