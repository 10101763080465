import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import { Hidden, Box, Typography, Container, Grid, Paper, List, ListItem } from '@material-ui/core';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image
import Homebg from "../images/homebg.jpg"

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

  const Homeback = {
    backgroundAttachment: 'fixed',
    backgroundImage: 'url('+ Homebg+')'
    }


const Expertise = () => (
  <Layout>
    <Seo title="Echo - Echocardiograph - Cardiac" description="Our company specialized in turnkey projects for hospital planning and management services helping organization achieve clinical integration and value-based care." />
    <ThemeProvider theme={theme}>
    <Aboutback title="Expertise" />

   <div style={Homeback}>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>
     <Grid container alignItems="center" justify="center">
     <Grid item xs={12} sm={6} md={5}>
         <Box pr={3} pb={3}>
         <Paper elevation={3}>
         <StaticImage
      src="../images/expertise.jpg"
      alt="Africorp Expertise"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
         </Paper>
         </Box>
         </Grid>

       <Grid item xs={12} sm={12} md={7} align="justify">
        <Typography gutterBottom paragraph variant="body1">
        Our company specialized in turnkey projects for hospital planning and management
services helping organization achieve clinical integration and value-based care.
</Typography>
<Typography gutterBottom paragraph variant="body1">
Vision to reality @ any phase of healthcare - Right from Concept to Commissioning.
</Typography>
<Typography gutterBottom paragraph variant="body1">
The journey of a hospital from concept to commissioning undergoes a series of steps,
procedures and techniques that eventually culminate into a world-class institution. As
a hospital consultant, we believe one has to envisage and factor in the highly dynamic fields
of both medicine and technology to keep the hospital state of the art at all times.
</Typography>
<Typography gutterBottom paragraph variant="body1">
Every hospital is unique. The scope of clinical services offered are driven by varying
influencing factors ranging from market conditions to demographic profile.
</Typography>
<Typography gutterBottom paragraph variant="body1">
To safely deliver Clinical Services in a start-up hospital requires courage, tenacity, knowledge and experience. We have these attributes and we know the process. We have been through the process many times and we know how to deliver a clinically robust, efficient and commercially sound hospital to our partners and clients. 
</Typography>


       </Grid>


       
       <Grid xs={12}>
       <Typography gutterBottom paragraph variant="body1">
Our experienced team have been providing hospital management and consultancy services to clients around the world for more than a decade. We have worked with public and private sector clients throughout the Middle East, Africa and Southeast Asia.
</Typography>
       <Typography gutterBottom variant="body1" paragraph>
       <b>AFRICORP HEALTHCARE</b> delivers these solutions in partnership with many other world leading technology vendors and combines them with the industries best after-sales services and support.
       </Typography>
       <List>
       <ListItem><Typography  gutterBottom variant="body1"><b>OUR SPECTRUM</b></Typography></ListItem>     
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Turn Key projects – Hospital.</Typography></ListItem>
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Biomedical Training for Nurses & Technicians.</Typography></ListItem>
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Medical Education Workshop for the new INNOVATIONS.</Typography></ListItem>
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Hospital maintenance including the HVAC and Air-conditioning.</Typography></ListItem>
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Tele - Medicine / Consultation / Reporting</Typography></ListItem>
  <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>Mobile Clinic</Typography></ListItem>
</List>
       </Grid>

     </Grid>
     </Box>
   </Container>
   </div>
   </div>

   <Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>

    </ThemeProvider>
  </Layout>
)

export default Expertise
